.report-style-class {
    height: 69vh;
    margin: 1% auto;
    width: 60%;
  }
  
  body {
    font-family: "Segoe UI";
    margin: 0;
  }
  
  .header {
    background: #3476ae 0 0 no-repeat padding-box;
    border: 1px solid #707070;
    height: 55px;
    left: 0;
    top: 0;
  }
  
  .displayMessage {
    color: #000000;
    font: normal 22px/27px Segoe UI;
    letter-spacing: 0;
    margin-top: 1%;
    opacity: 1;
    text-align: center;
  }
  
  .hr {
    border: 1px solid #e0e0e0;
    opacity: 1;
  }
  
  .controls {
    margin-top: 1%;
    text-align: center;
  }
  
  .footer1 {
    clear: both;
    background: #eef3f8 0 0 no-repeat padding-box;
    bottom: 0;
    position: fixed;
    height: 39px;
    opacity: 1;
    width: 100%;

  }
  
  .footer-text {
    font: Regular 16px/21px Segoe UI;
    height: 21px;
    letter-spacing: 0;
    margin-top: 9px;
    opacity: 1;
    position: relative;
    text-align: center;
    width: 100%;
  }
  
  .footer-text > a {
    color: #278ce2;
    font: Regular 16px/21px Segoe UI;
    letter-spacing: 0;
    text-decoration: underline;
  }
  
  .title {
    color: #ffffff;
    font: Bold 22px/27px Segoe UI;
    letter-spacing: 0;
    margin: 13px;
    margin-left: 36px;
    opacity: 1;
    text-align: left;
  }
  
  button {
    background: #337ab7;
    border: 0;
    border-radius: 5px;
    color: #ffffff;
    font-size: medium;
    height: 35px;
    margin-right: 15px;
    width: 150px;
  }
  
  button:onfocus {
    outline: none;
  }
  
  iframe {
    border: none;
  }
  .reportClass
  {
    height: calc(100vh - 150px);
    padding-top:0.2em;
    padding-bottom:0.2em;
  }
  .menu  {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #333;
  }
  
  .menu li {
    width: 100%;
     float: left;
  }
  
  .menu li a {
    display: block;
    color: white;
    text-align: left;
    /*padding: 14px 16px;*/
    padding: 7px 16px;
    text-decoration: none;
    width:100%;
  }
  
  .menu li a:hover:not(.active) {
    background-color: #111;

  }
  
  .menu li a.active {
    float: left;
  }
  
  .active {
    background-color: #4CAF50;
  }
  .row
  {
    width:100%;
  }